.authbox {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  width: 400px;
  left: 50%;
  margin-left: -200px;
  background-color: #efefef;
  padding: 20px;
  top: 50%;
}
.authbox input,
.authbox select {
  border-radius: 0px;
  font-size: 14px;
}
.authbox img {
  vertical-align: initial;
}
.authbox .btn-success {
  background-color: #1DBFF0;
  border-color: #1DBFF0;
}
.authbox .authbox-title {
  font-size: 20px;
  margin-bottom: 20px;
}
.authbox .authbox-subtitle {
  font-size: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
.authbox-small {
  margin-top: -125px;
}
.authbox-large {
  margin-top: -250px;
}
